import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";

const NumberCircle = styled("div")<{selected?: boolean, selectable?: boolean}>(({theme, selected, selectable}) => ({
    fontSize: "0.9rem",
    width: 34,
    height: 34,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",

    backgroundColor: selected ? grey["200"] : undefined,

    "&:hover": {
        backgroundColor: selectable ? grey["200"] : undefined,
    }
}))

export function PageButton(props: {
    page?: number | null;
    selected: boolean;
    onClick(): void;
}) {
    return (
        <NumberCircle selected={props.selected} selectable={!!props.page} onClick={props.onClick}>
            {props.page || "..."}
        </NumberCircle>
    );
}