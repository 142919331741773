import { PageContent } from "../misc/PageContent";
import {Container} from "../misc/Container"
import Card from "@mui/material/Card";
import {Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {api, Store} from "../api/API";
import {Search} from "./Search";
import {Route} from "./Route";
import {grey} from "@mui/material/colors";
import {useParams, useSearchParams} from "react-router-dom";
import {useAsync2} from "nate-react-api-helpers";

export function PlanTripPage() {
    const [stores, setStores] = useState<Store[]>([]);
    const [params] = useSearchParams();
    const ids = params.get("ids");

    const result = useAsync2(async input => {
        if(!input.stores) return null;

        const result = await api.storeSearch({byId: input.stores})
        setStores(result);
    }, {stores: ids}, [ids]);


    return (
        <Container>
            <PageContent>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Card style={{padding: 16, paddingBottom: 0}}>
                            <div style={{paddingBottom: 16}}>
                                <Typography variant="h6">Plan Trip</Typography>
                            </div>
                            {result.LoadingOrErrorElement ? result.LoadingOrErrorElement : <Grid container spacing={1} alignItems="stretch">
                                <Grid item>
                                    <Route stores={stores} onChange={setStores} />
                                </Grid>
                                <Grid item style={{borderLeft: "1px solid " + grey["300"], minHeight: 300}}>
                                    <Search stores={stores} onChange={setStores} />
                                </Grid>
                            </Grid>}
                        </Card>
                    </Grid>
                </Grid>
            </PageContent>
        </Container>
    )
}