import {Store} from "../api/API";
import {Button, Grid, IconButton} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp, RemoveCircle} from "@mui/icons-material";

export function Route(props: {
    stores: Store[];
    onChange(stores: Store[]): void;
}) {
    return (
        <div style={{paddingRight: 8, width: 400, display: "flex", flexDirection: "column", height: "100%"}}>
            <div style={{flex: 1}}>
                <div style={{fontWeight: 600}}>Route</div>

                <div style={{height: 10}} />
                {props.stores.length === 0 && <div>
                    Find stores in the right panel to add to your route.
                </div>}
                {props.stores.map((s, index) => (<div key={s.id} style={{paddingBottom: 16}}>
                    <Grid container spacing={1}>
                      <Grid item xs>
                          <div style={{fontSize: "1rem"}}>{s.name} {s.refNumber !== s.name ? "(" + s.refNumber + ")" : null}</div>
                          <div style={{fontSize: "0.85rem", paddingTop: 6}}>
                            <div>{s.address1}</div>
                            <div>{s.city}, {s.province}</div>
                            <div>{s.postal}</div>
                          </div>
                      </Grid>
                        <Grid item>
                            <IconButton onClick={() => props.onChange(props.stores.filter(st => st !== s))}>
                                <RemoveCircle />
                            </IconButton>
                            <IconButton disabled={index === props.stores.length-1} onClick={() => {
                                let cpy = props.stores.slice(0);
                                let other = cpy[index+1];
                                cpy[index+1] = s;
                                cpy[index] = other;
                                props.onChange(cpy);
                            }}>
                                <KeyboardArrowDown />
                            </IconButton>
                            <IconButton disabled={index === 0} onClick={() => {
                                let cpy = props.stores.slice(0);
                                let other = cpy[index-1];
                                cpy[index-1] = s;
                                cpy[index] = other;
                                props.onChange(cpy);
                            }}>
                                <KeyboardArrowUp />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>))}
            </div>

            <div style={{paddingBottom: 16 ,paddingRight: 8}}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button variant="contained" color="primary" disabled={props.stores.length < 2} onClick={() => {
                            let address = function(store: Store) {
                                return store.address1 + " " + store.city + ", " + store.province + ", " + store.postal;
                            }
                            const qr = new URLSearchParams();
                            qr.set("api", "1");
                            qr.set("origin", address(props.stores[0]));

                            let waypoints: string[] = [];

                            for(let i = 1; i < props.stores.length-1; i++) {
                                waypoints.push(address(props.stores[i]));
                            }

                            if(waypoints.length > 0) {
                                qr.set("waypoints", waypoints.join("|"))
                            }
                            qr.set("destination", address(props.stores[props.stores.length-1]));
                            let url = "https://www.google.com/maps/dir/?" + qr.toString();
                            window.open(url, "_blank");
                        }}>
                            Open in Google Maps
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}