import {createContext, PropsWithChildren, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {range} from "nate-react-api-helpers";
import moment from "moment";

export const minDate = new Date("2018-01-01T00:00");
export const availableYears = range(minDate.getFullYear(), new Date().getFullYear()+1).map(r => r.toString()).reverse();
export const availableMonths: string[] = (function(){
    const months: string[] = [];
    let cur = moment().utc().startOf("month");
    const min = moment(minDate).utc().startOf("month");

    while(cur.isSameOrAfter(min)) {
        months.push(cur.format("MMM YYYY"));
        cur.add(-1, "month");
    }

    return months;
})();
export const availableQuarters = (function(){
    const months: string[] = [];
    let cur = moment().utc().startOf("quarter");
    const min = moment(minDate).utc().startOf("quarter");

    while(cur.isSameOrAfter(min)) {
        months.push(cur.format("[Q]Q YYYY"));
        cur.add(-1, "quarter");
    }

    return months;
})();

export const currentYear = new Date().getFullYear().toString();
export const currentMonth = availableMonths[0];

export enum Resolution {
    Month =  "mo",
    Quarter = "qt",
    Year = "yr",
}

export const DateSelectedContext = createContext({
    year: currentYear,
    detail: currentMonth,
    detailResolution: Resolution.Month as Resolution,
    changeYear: (value: string) => {},
    changeDetail: (value: string, resolution?: Resolution) => {},
})

export function DatePickerProvider(props: PropsWithChildren<{}>) {
    const location = useLocation();
    const nav = useNavigate();

    const [year, setYear] = useState(currentYear);
    const [detail, setDetail] = useState(currentMonth);
    const [detailResolution, setDetailResolution] = useState<Resolution>(Resolution.Month);

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        const yr = search.get("year");
        if(yr) {
            setYear(yr);
        } else {
            setYear(currentYear);
        }

        const dv = search.get("dv");
        const dr = search.get("dr");

        if(dv && dr) {
            setDetail(dv);
            setDetailResolution(dr as Resolution);
        } else {
            setDetail(currentMonth);
            setDetailResolution(Resolution.Month);
        }
    }, [location.search]);


    const ctx = useMemo(() => {
        return {
            year: year,
            changeYear: (value: string) => {
                setYear(value);

                const search = new URLSearchParams(window.location.search);
                if(value == currentYear) {
                    search.delete("year");
                } else {
                    search.set("year", value);
                }

                nav(window.location.pathname + "?" + search.toString())
            },
            detail: detail,
            detailResolution: detailResolution,
            changeDetail: (value: string, resolution?: Resolution) => {
                if(!resolution) {
                    resolution = detailResolution
                }

                setDetail(value);

                const search = new URLSearchParams(window.location.search);
                if(value == currentMonth && resolution === Resolution.Month) {
                    search.delete("dr");
                    search.delete("dv");
                } else {
                    search.set("dv", value);
                    search.set("dr", resolution);
                }

                nav(window.location.pathname + "?" + search.toString())
            }
        }
    }, [year, detail, detailResolution]);

    return <DateSelectedContext.Provider value={ctx}>
        {props.children}
    </DateSelectedContext.Provider>
}