import {PropsWithChildren} from "react";

export function PageContent(props: PropsWithChildren<{}>) {
    return (
        <div style={{display: "flex", justifyContent: "center",
            paddingLeft: 32, paddingRight: 32,
            height: "100%",
        }}>
            <div style={{maxWidth: 1800, width: "100%",
                display: "flex", flexDirection: "column",
            }}>
                {props.children}
            </div>
        </div>
    );
}