import {SummaryTable} from "./summary/SummaryTable";
import {api} from "../api/API";
import {Pagination, usePageState} from "../misc/Pagination";
import {useContext, useState} from "react";
import {availableMonths, currentMonth, DateSelectedContext} from "./DatePickerProvider";
import {useDataSource} from "../misc/DataSource";
import {Card, Checkbox, Grid, TextField} from "@mui/material";
import {CardLabel} from "./summary/CardLabel";
import {Downloader} from "./summary/Downloader";
import {IntervalSelector} from "../misc/IntervalSelector";
import {PeriodSelector} from "../misc/PeriodSelector";
import {CreateTripWithSelection} from "./summary/CreateTripWithSelection";
import {StyledTableInner} from "../misc/StyledTable";
import {SortableHeader} from "./summary/SortableHeader";
import {SubtleLink} from "../misc/SubtleLink";
import {DeltaDollarCell} from "./summary/DeltaDollarCell";
import {formatCents, range} from "nate-react-api-helpers";
import {Delta} from "./Delta";
import {TrajectoryGraph} from "./summary/TrajectoryGraph";
import {MoreButton} from "./summary/MoreButton";
import {CompactSelect} from "./CompactSelect";

const zeroMonthOptions = range(1, 10).map(r => r.toString());

export function ZeroMonths() {
    const pgState = usePageState(8);
    const {detail} = useContext(DateSelectedContext);
    const [selected, setSelected] = useState<number[]>([]);
    const [search, setSearch] = useState("");
    const [orderBy, setOrderBy] = useState("name");
    const [orderByDirection, setOrderByDirection] = useState<"asc"|"desc">("desc");
    const [n, setN] = useState(2);
    const [endMonth, setEndMonth] = useState(currentMonth);

    const data = useDataSource(input => api.zeroMonths(input), {
        detail: detail,
        search: search,
        pageSize: pgState.pageSize,
        page: pgState.page,
        orderBy,
        orderByDirection,
        nZeros: n,
        endingOn: endMonth,
    }, [
        pgState.page, detail, search,
        orderBy, orderByDirection,
        endMonth,
        n,
    ]);

    const ordering = {
        column: orderBy,
        onColumnChange: setOrderBy,
        direction: orderByDirection,
        onDirectionChange: setOrderByDirection,
    }

    return (
        <Card style={{height: "100%", width: "100%"}}>
            <CardLabel>
                Zero Months
            </CardLabel>
            <div style={{padding: 4, paddingLeft: 16, paddingRight: 16}}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <TextField variant="outlined" onChange={e => setSearch(e.target.value)} placeholder="Filter for Name or Tag" size="small" fullWidth />
                    </Grid>
                    <Grid item>
                        <Downloader fileName="trending-downwards.csv" onDownload={data.download} />
                    </Grid>
                    <Grid item>
                        <CompactSelect label="# Zero Months" value={n.toString()} onChange={v => setN(parseInt(v))} options={zeroMonthOptions} />
                    </Grid>
                    <Grid item>
                        <CompactSelect label="Ending On" value={endMonth} onChange={setEndMonth} options={availableMonths} />
                    </Grid>
                </Grid>
            </div>
            <CreateTripWithSelection selected={selected} />

            {data.LoadingOrErrorElement ? <div style={{height: 200}}>
                    {data.LoadingOrErrorElement}
                </div> :
                <StyledTableInner>
                    <thead>
                    <tr>
                        <th />
                        <SortableHeader {...ordering} name="name">Name</SortableHeader>
                        <SortableHeader {...ordering} name="period_0">Last {n} months</SortableHeader>
                        <SortableHeader {...ordering} name={"period_" + n}>Month {n+1}</SortableHeader>
                        <SortableHeader {...ordering} name={"period_" + (n+1)}>Month {n+2}</SortableHeader>
                        <SortableHeader {...ordering} name="avg">Last-12 Avg</SortableHeader>
                        <SortableHeader {...ordering} name="peak">Last-12 Peak</SortableHeader>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.result?.data?.map(rw => {
                        const list = [rw.period11, rw.period10, rw.period9, rw.period8, rw.period7, rw.period6, rw.period5, rw.period4, rw.period3, rw.period2, rw.period1, rw.period0];
                        const rev = list.reverse();

                        return (<tr key={rw.id}>
                            <td className="checkbox">
                                <Checkbox onClick={e => {
                                    if(selected.indexOf(rw.id) !== -1) {
                                        setSelected(old => old.filter(r => r !== rw.id));
                                    } else {
                                        setSelected(old => [...old, rw.id]);
                                    }
                                }} checked={selected.indexOf(rw.id) !== -1} />
                            </td>
                            <td>
                                <SubtleLink to={"/store/" + rw.id}>{rw.name}</SubtleLink>
                            </td>
                            <DeltaDollarCell>
                                {formatCents(rw.period0)}
                            </DeltaDollarCell>
                            <DeltaDollarCell>
                                {formatCents(rev[n])}
                                <Delta basis={rev[n+1]} current={rev[n]} noIcon />
                            </DeltaDollarCell>
                            <DeltaDollarCell>
                                {formatCents(rev[n+1])}
                                <Delta basis={rev[n+2]} current={rev[n+1]} noIcon />
                            </DeltaDollarCell>
                            <td>{formatCents(rw.avg)}</td>
                            <td>{formatCents(rw.peak)}</td>
                            <td>
                                <TrajectoryGraph samples={list} />
                            </td>
                            <td>
                                <MoreButton id={rw.id} copperId={rw.copperId} />
                            </td>
                        </tr>);
                    })}
                    </tbody>
                </StyledTableInner>
            }

            <Pagination value={data.result} state={pgState} />
        </Card>
    );
}