import {api, Store} from "../api/API";
import {useAsync2} from "nate-react-api-helpers";
import {useState} from "react";
import {Grid, MenuItem, styled, TextField} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {grey} from "@mui/material/colors";

export function Search(props: {
    stores: Store[];
    onChange(stores: Store[]): void;
}) {
    const [search, setSearch] = useState("");

    const searcher = useAsync2(input => {
        return api.storeSearch(input);
    }, {search: search}, [search]);
    const storeIds = props.stores.map(s => s.id);

    return (
        <div style={{width: 400}}>
            <div style={{fontWeight: 600}}>Find Store</div>
            <div style={{height: 8}} />
            <TextField variant="outlined"
                       onChange={e => setSearch(e.target.value)}
                       placeholder="Filter for Name or Tag" size="small"
                       fullWidth />
            <div style={{height: 8}} />

            {searcher.LoadingOrErrorElement}
            {searcher.asList.length === 0 && !searcher.loading && search != "" && <div>
                No results for '{search}'
            </div>}
            {searcher.asList
                .filter(v => storeIds.indexOf(v.id) === -1)
                .map(v => (<div key={v.id}>
                <MenuItem onClick={() => {
                    props.onChange([...props.stores, v]);
                }}>
                    <Grid container style={{width: "100%"}} spacing={1}>
                        <Grid item xs>
                            <div style={{whiteSpace: "normal"}}>
                                <div style={{fontSize: "1.1rem"}}>{v.name}</div>
                                {!!(v.address1 || v.city || v.province) ? (<div>{v.address1} {v.city}, {v.province}</div>) : null}
                            </div>
                        </Grid>
                        <Grid item>
                            <Add>
                                <AddCircle color="inherit" fontSize="inherit" />
                            </Add>
                        </Grid>
                    </Grid>
                </MenuItem>
            </div>))}
        </div>
    )
}

const Add = styled("div")(() => ({
    color: grey["500"],
    fontSize: "1.3rem",
}))