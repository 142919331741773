import { Container } from "../misc/Container";
import {PageContent} from "../misc/PageContent";
import { Header } from "./Header";
import { DatePickerProvider } from "./DatePickerProvider";
import {Grid} from "@mui/material";
import {Trending} from "./Trending";
import {ByRegion} from "./ByRegion";
import {LowVolume} from "./LowVolume";
import {ZeroMonths} from "./ZeroMonths";

export function HomePage() {

    return (
        <Container>
            <DatePickerProvider>
                <PageContent>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12}>
                            <Header />
                        </Grid>
                        <Grid item xs={6}>
                            <Trending />
                        </Grid>
                        <Grid item xs={6}>
                            <ByRegion />
                        </Grid>
                        <Grid item xs={6}>
                            <ZeroMonths />
                        </Grid>
                        <Grid item xs={6}>
                            <LowVolume />
                        </Grid>
                    </Grid>
                </PageContent>
            </DatePickerProvider>
        </Container>
    )
}