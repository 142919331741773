import {DatePickerProvider} from "../../home/DatePickerProvider";
import {PageContent} from "../../misc/PageContent";
import {
    Button,
    Card,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    styled,
    TextField
} from "@mui/material";
import {Container} from "../../misc/Container";
import {useEffect, useState} from "react";
import moment from "moment";
import {downloadBlob, range, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {grey} from "@mui/material/colors";
import {MonthPicker} from "../pivot/MonthPicker";

const yearStart = moment().startOf("year");
const groupingOptions = [
    "store",
    "chain",
    "distributor",
    "region",
    "province",
    "city",
    "tag",
    "month",
    "quarter",
    "product",
]

const dateFormat = "YYYY MMM"

export function StandardReportPage() {

    const [search, setSearch] = useState("");

    const [dateStart, setDateStart] = useState(yearStart.format(dateFormat));
    const [dateEnd, setDateEnd] = useState(moment().format(dateFormat));
    const [dateRange, setDateRange] = useState("ytd");

    const [columns, setColumns] = useState<string[]>([]);

    useEffect(() => {
        if(columns.length === 0) {
            setColumns(["-"]);
            return;
        }

        if(columns[columns.length-1] !== "-") {
            if(columns.length < groupingOptions.length) {
                setColumns([...columns, "-"]);
                return;
            }
        }

        if(columns.indexOf("-") !== columns.length-1) {
            setColumns(columns.filter(g => g !== "-"));
        }

    }, [columns]);

    useEffect(() => {
        switch(dateRange) {
            case "ytd":
                setDateStart(moment().startOf("year").format(dateFormat));
                setDateEnd(moment().format(dateFormat));
                break;
            case "qt":
                setDateStart(moment().startOf("quarter").add(-1, "day").startOf("quarter").format(dateFormat));
                setDateEnd(moment().startOf("quarter").format(dateFormat))
                break;
            case "last-12":
                setDateStart(moment().startOf("month").add(-12, "month").format(dateFormat));
                setDateEnd(moment().startOf("month").format(dateFormat));
                break;
            case "last-6":
                setDateStart(moment().startOf("month").add(-6, "month").format(dateFormat));
                setDateEnd(moment().startOf("month").format(dateFormat));
                break;
            default:
            case "start-end":
                break;
        }
    }, [dateRange]);

    const report = useAsyncAction(async (input) => {
        const result = await api.report(input);
        if(input.download) {
            const csv = toCSV([result.columns.map(c => c.name), ...result.values])
            const blob = new Blob([csv], { type: "text/csv" });
            downloadBlob(blob, "report-" + moment().format("YYYY-MMM") + ".csv")
        }

        return result;
    }, []);

    const result = report.result;

    return (
        <Container>
            <DatePickerProvider>
                <PageContent>
                    <Card>
                        <div style={{padding: 16}}>
                            <div>
                                <div style={{fontSize: "1.2rem"}}>Standard Report</div>
                            </div>
                            <Grid container spacing={2} alignItems="stretch">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs>
                                            <div>Store Search Criteria</div>
                                            <TextField variant="outlined" size="small" value={search} onChange={e => setSearch(e.target.value)} fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <div>Date Range</div>
                                            <Select size="small" value={dateRange} onChange={e => setDateRange((e.target.value as any).toString())}>
                                                <MenuItem value="ytd">YTD</MenuItem>
                                                <MenuItem value="qt">Last Quarter</MenuItem>
                                                <MenuItem value="last-12">Last 12 Months</MenuItem>
                                                <MenuItem value="last-6">Last 6 Months</MenuItem>
                                                <MenuItem value="start-end">Start - End</MenuItem>
                                            </Select>
                                        </Grid>
                                        {dateRange === "start-end" && <Grid item>
                                            <div>Start</div>
                                            <MonthPicker value={dateStart} onChange={setDateStart} />
                                        </Grid>}
                                        {dateRange === "start-end" && <Grid item>
                                            <div>End</div>
                                            <MonthPicker value={dateEnd} onChange={setDateEnd} />
                                        </Grid>}
                                    </Grid>

                                    <div style={{height: 8}} />
                                    <div>Columns</div>
                                    <Grid container spacing={1}>
                                        {columns.map((g, index) => (
                                            <Grid item key={index}>
                                                <Select value={g} onChange={e => {
                                                    columns[index] = (e.target.value as any).toString();
                                                    setColumns(columns.slice(0))
                                                }} size="small">
                                                    <MenuItem key="none" value="-">{index !== columns.length-1 ? "(remove)" : "(none)"}</MenuItem>
                                                    {groupingOptions.map(g => <MenuItem key={g} value={g}>{ucFirst(g)}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                        ))}
                                        <Grid item xs />
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={() => {
                                                report.callback({
                                                    search: search,
                                                    columns: columns.filter(g => g !== "-"),
                                                    dateStart: moment.utc(dateStart, "YYYY MMM").toDate(),
                                                    dateEnd: moment.utc(dateEnd, "YYYY MMM").toDate(),
                                                    download: false,
                                                });
                                            }}>View Report</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" color="primary" onClick={() => {
                                                report.callback({
                                                    search: search,
                                                    columns: columns.filter(g => g !== "-"),
                                                    dateStart: moment.utc(dateStart, "YYYY MMM").toDate(),
                                                    dateEnd: moment.utc(dateEnd, "YYYY MMM").toDate(),
                                                    download: true,
                                                });
                                            }}>Download to Excel</Button>
                                        </Grid>
                                    </Grid>

                                    <div style={{height: 8}} />

                                    {report.loading ? <CircularProgress />: null}
                                    {report.error ? <div style={{color: "red"}}>{report.error}</div>: null}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider />
                            <Table>
                                <thead>
                                <tr>
                                    {result?.columns.map((c, index) =>
                                        <th key={index} style={{textAlign: "left", maxWidth: index === result.columns.length - 1 ? 150 : 400}}>
                                            {ucFirst(c.name)}
                                        </th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {result?.values.map((v, rowIndex) => <tr key={rowIndex}>
                                    {v.map((cell, cellIndex) => <td key={cellIndex} style={["money", "number"].indexOf(result.columns[cellIndex].kind) !== -1 ? {textAlign: "right"} : undefined}>
                                        {cell}
                                    </td>)}
                                </tr>)}
                                {result?.values.length === 0 ? <tr key="nothing"><td colSpan={result.columns.length}>
                                    No results. Try adjusting your search or date range.
                                </td></tr> : null}
                                </tbody>
                            </Table>
                    </Card>
                </PageContent>
            </DatePickerProvider>
        </Container>
    )
}

export function ucFirst(input: string) {
    if(!input) return input;
    return input[0].toUpperCase() + input.substring(1)
}

const Table = styled("table")(props => ({
    width: "100%",
    borderCollapse: "collapse",
    "& th, & td": {
        paddingLeft: 8,
        paddingRight: 8,
        borderBottom: "1px solid " + grey["200"],
        borderLeft: "1px solid " + grey["200"],

        "&:first-child": {
            borderLeft: "none",
        }
    }
}))

function toCSV(data: string[][]) {
    const rows = data.map((row) => {
        return row
            .map((value) => {
                if (/[,\r\n]/.exec(value) !== null) {
                    return '"' + value.replace(/"/g, '""') + '"';
                }

                return value;
            })
            .join(",");
    });

    return rows.join("\r\n");
}