import {Card, Checkbox, Grid} from "@mui/material";
import {formatCents, useAsync2} from "nate-react-api-helpers";
import {api} from "../api/API";
import {CardLabel} from "../home/summary/CardLabel";
import {Downloader} from "../home/summary/Downloader";
import {useDataSource} from "../misc/DataSource";
import {Pagination, usePageState} from "../misc/Pagination";
import {useState} from "react";
import {SortableHeader} from "../home/summary/SortableHeader";
import {Delta} from "../home/Delta";
import {StyledTableInner} from "../misc/StyledTable";
import moment from "moment";

export function History(props: {
    id: any;
}) {
    const pgState = usePageState(50);
    const [search, setSearch] = useState("");
    const [orderBy, setOrderBy] = useState("date");
    const [orderByDirection, setOrderByDirection] = useState<"asc"|"desc">("desc");

    const data = useDataSource(input => api.storeHistory(input), {
        id: props.id,
        search: search,
        pageSize: pgState.pageSize,
        page: pgState.page,
        orderBy,
        orderByDirection,

    }, [
        pgState.page, search,
        orderBy, orderByDirection,
        props.id,
    ]);

    const ordering = {
        column: orderBy,
        onColumnChange: setOrderBy,
        direction: orderByDirection,
        onDirectionChange: setOrderByDirection,
    }

    return (
        <Card>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <CardLabel>
                        History
                    </CardLabel>
                </Grid>
                <Grid item>
                    <div style={{padding: 10}}>
                        <Downloader fileName="history.csv" onDownload={data.download} />
                    </div>
                </Grid>
            </Grid>
            {data.LoadingOrErrorElement ? <div style={{height: 200}}>
                {data.LoadingOrErrorElement}
            </div> : <StyledTableInner>
                <thead>
                <tr>
                    <SortableHeader {...ordering} name="date">Date</SortableHeader>
                    <SortableHeader {...ordering} name="volume">Volume</SortableHeader>
                    <th>Mo/Mo</th>
                    <th>Yo/Yo</th>
                </tr>
                </thead>
                <tbody>
                {data.result?.data?.map(rw => <tr key={rw.date}>
                    <td>
                        {moment(rw.date).utc().format("YYYY - MMMM")}
                    </td>
                    <td style={{textAlign: "right"}}>
                        {formatCents(rw.current)}
                    </td>
                    <td>
                        <Delta basis={rw.lastMonth || 0} current={rw.current} noIcon />
                    </td>
                    <td>
                        <Delta basis={rw.lastYear || 0} current={rw.current} noIcon />
                    </td>
                </tr>)}
                </tbody>
            </StyledTableInner>}
            <Pagination value={data.result} state={pgState} />
        </Card>
    )
}