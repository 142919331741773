import {MenuItem, Select} from "@mui/material";
import {range} from "nate-react-api-helpers";
import moment from "moment/moment";

export function MonthPicker(props: {
    value: string;
    onChange(value: string): void;
}) {
    return (
        <>
            <Select size="small" value={props.value.split(" ")[0]} onChange={e => {
                props.onChange(e.target.value as string + " " + props.value.split(" ")[1])
            }}>
                {range(0, 5).map(v => {
                    let y = new Date().getFullYear() - v;
                    return <MenuItem value={y.toString()} key={y.toString()}>{y}</MenuItem>
                })}
            </Select>
            <Select size="small" value={props.value.split(" ")[1]} onChange={e => {
                props.onChange(props.value.split(" ")[0] + " " + e.target.value as string)
            }}>
                {range(0, 12).map(v => {
                    const mo = moment().startOf("year").add(v, "month").format("MMM")
                    return <MenuItem value={mo} key={mo}>{mo}</MenuItem>
                })}
            </Select>
        </>
    )
}