import {SummaryTable} from "./summary/SummaryTable";
import {api} from "../api/API";

export function ByRegion() {

    return (
        <SummaryTable
            title="By Region"
            data={input => api.byRegion(input)}
            notStore
        />
    )
}