import {styled} from "@mui/material";
import {Label} from "../misc/Label";
import {BigNumber} from "../misc/BigNumber";
import {AsyncResult2} from "nate-react-api-helpers";
import {Delta} from "./Delta";
import {grey} from "@mui/material/colors";

export function HeaderCell(props: {
    label: string
    value: any;
    current?: number;
    basis?: number;
    data: AsyncResult2<any>;
}) {
    return (
        <Cell>
            {props.data.loading ?
                props.data.LoadingOrErrorElement :
                <>
                    <Label>{props.label}</Label>
                    <BigNumber>{props.value}</BigNumber>
                    {props.current !== undefined && props.basis !== undefined && <Delta current={props.current} basis={props.basis} />}
                </>}
        </Cell>
    )
}

export const Cell = styled("div")(({theme})=> ({
    padding: 16,
    flex: 1,
    flexBasis: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    "&:not(:first-child)": {
        borderTop: "1px solid " + grey["200"],
    }
}));