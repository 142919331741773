import {CardBlock} from "../misc/CardBlock";
import {formatCents, parseCents, useAsync2} from "nate-react-api-helpers";
import {api} from "../api/API";
import {useSnackAction} from "../misc/SnackAction";
import {StyledTable} from "../misc/StyledTable";
import {Checkbox, Grid, IconButton, Typography} from "@mui/material";
import {useState} from "react";
import {DefaultPriceRightActions} from "./DefaultPriceRightActions";
import {DefaultPriceEditRow} from "./DefaultPriceEditRow";

export function DefaultPrices() {
    const data = useAsync2(() => api.getDefaultPrices(), {}, []);
    const reload = data.reload;
    const [selected, setSelected] = useState<number[]>([])

    const update = useSnackAction(async (input: any) => {
        await api.updateDefaultPrices(input);
        reload();
    }, [reload]);

    return (
        <CardBlock title="Standard Pricing" right={
            <DefaultPriceRightActions selected={selected} onUpdate={update} />
        }>
            <StyledTable>
                <thead>
                <tr>
                    <th className="checkbox">
                        <Checkbox checked={selected.length > 0 && selected.length === data.asList.length} onClick={() => {
                            if(selected.length > 0) {
                                setSelected([])
                                return;
                            }

                            setSelected(data.asList.map(l => l.id));
                        }} />
                    </th>
                    <th>
                        <div style={{width: 100}}>SKU</div>
                    </th>
                    <th>Description</th>
                    <th>
                        <div style={{width: 80}}>
                            Default Price
                        </div>
                    </th>
                    <th />
                </tr>
                </thead>
                <tbody>
                {data.loadingOrError && <tr key="loading">
                    <td colSpan={4}>{data.LoadingOrErrorElement}</td>
                </tr>}
                <DefaultPriceEditRow key={"create-" + data.asList.length} onUpdate={update} />

                {data.asList.map(l =>
                    <DefaultPriceEditRow key={"update-" + l.id} value={l} onUpdate={update} checked={selected.indexOf(l.id) !== -1} onCheck={() => {
                        if(selected.indexOf(l.id) !== -1) {
                            setSelected(old => old.filter(v => v !== l.id));
                        } else {
                            setSelected(old => [...old, l.id]);
                        }
                    }} />)}
                </tbody>
            </StyledTable>
        </CardBlock>
    )
}