import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import { AuthProvider } from 'nate-react-api-helpers';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {HomePage} from "./home/HomePage";
import {api} from "./api/API";
import {Login} from "./misc/Login";
import {SettingsPage} from "./settings/SettingsPage";
import {SnackbarProvider} from "notistack";
import {StorePage} from "./store/StorePage";
import { PlanTripPage } from './trip/PlanTripPage';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {StandardReportPage} from "./report/standard/StandardReportPage";
import {PivotReportPage} from "./report/pivot/PivotReportPage";


const theme = createTheme({
    palette: {
        primary: {
          main: "#000000",
        },
        secondary: {
            main: "#FCDF00",
        }
    },
    components: {
        MuiCssBaseline: {
        },
    },
});

function App() {
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider onPing={() => api.ping()}>
              <ThemeProvider theme={theme}>
                  <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
                      <CssBaseline />
                      <BrowserRouter>
                          <Routes>
                              <Route path="/settings" element={<SettingsPage />} />
                              <Route path="/plan-trip" element={<PlanTripPage />} />
                              <Route path="/store/:id" element={<StorePage />} />
                              <Route path="/report/standard" element={<StandardReportPage />} />
                              <Route path="/report/pivot" element={<PivotReportPage />} />
                              <Route path="/" element={<HomePage />} />
                          </Routes>
                      </BrowserRouter>
                      <Login />
                  </SnackbarProvider>
              </ThemeProvider>
          </AuthProvider>
      </LocalizationProvider>
  );
}

export default App;
