import {useAsyncAction, useAuthenticated} from "nate-react-api-helpers";
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import {api} from "../api/API";
import {useState} from "react";

export function Login() {
    const auth = useAuthenticated();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("");

    const action = useAsyncAction(async (input) => {
        await api.login(input)
        window.location.reload();
    }, []);
    if(auth.authenticated) return null;

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault();
                action.callback({username: username, password: password})
            }}>
                <DialogContent style={{maxWidth: 300}}>
                    <div style={{height: 10}} />
                    <img src="/logo-big.svg" style={{width: "100%", padding: 10}} />
                    <div style={{height: 20}} />
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField name="email" label="Email" variant="outlined" fullWidth onChange={e => setUsername(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField name="password" type="password" label="Password" variant="outlined" fullWidth onChange={e => setPassword(e.target.value)} />
                        </Grid>
                    </Grid>
                    <div style={{height: 20}} />
                    {action.LoadingElement}
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button variant="contained" color="secondary" type="submit">
                            Login
                        </Button>
                    </div>
                </DialogContent>
            </form>
        </Dialog>
    )
}