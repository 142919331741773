import {Download} from "@mui/icons-material";
import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";

const Wrapper = styled("div")(() => ({
    fontSize: "1.4rem",
    height: "1em",
    width: "1em",
    cursor: "pointer",
    borderRadius: "50%",

    "&:hover": {
        backgroundColor: grey["300"],
    }
}))

export function Downloader(props: {
    fileName: string;
    onDownload(fileName: string): void;
}) {
    return (
        <Wrapper onClick={() => props.onDownload(props.fileName)}>
            <Download fontSize="inherit" />
        </Wrapper>
    )
}