import {PropsWithChildren} from "react";
import {grey} from "@mui/material/colors";
import {Grid} from "@mui/material";

export function Tag(props: PropsWithChildren<{}>) {

    return (
        <Grid item>
            <div style={{borderRadius: 2, backgroundColor: grey["300"], padding: "0px 8px"}}>
                {props.children}
            </div>
        </Grid>
    )
}