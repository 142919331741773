import {Card, Grid, Typography} from "@mui/material";
import {PropsWithChildren} from "react";

export function CardBlock(props: PropsWithChildren<{
    title: string;
    right?: any;
}>) {

    return (
        <Card style={{padding: 8}}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography style={{fontWeight: 500, padding: 8}}>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item>
                    {props.right}
                </Grid>
            </Grid>


            {props.children}
        </Card>
    )
}