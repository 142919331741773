import {useSnackbar} from "notistack";
import {useCallback} from "react";

export function useSnackAction<T>(
    callback: (input: T) => Promise<void>,
    deps: any[]
) {

    const {enqueueSnackbar} = useSnackbar();
    return useCallback(async (input: T) => {
        try {
            enqueueSnackbar("Updating...");
            await callback(input)
            enqueueSnackbar("Success", {
                variant: "success",
            });
        } catch(e: any) {
            enqueueSnackbar(e.toString(), {
                variant: "error",
            });
        }
    }, [...deps, enqueueSnackbar]);
}