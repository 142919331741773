import Card from "@mui/material/Card";
import {IconButton, styled, Typography} from "@mui/material";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../api/API";
import moment from "moment";
import {Delete, Download} from "@mui/icons-material";
import {useSnackAction} from "../misc/SnackAction";
import {Pagination, usePageState} from "../misc/Pagination";
import {StyledTable} from "../misc/StyledTable";
import {CardBlock} from "../misc/CardBlock";



export function UploadHistory() {
    const pState = usePageState();
    const data = useAsync2((input) => api.getSrcReportHistory(input), {
        page: pState.page,
        pageSize: pState.pageSize,
    }, [pState.page, pState.pageSize]);
    const reload = data.reload;

    const remove = useSnackAction(async (input: any) => {
        await api.removeSrcReport(input);
        pState.setPage(0);
        reload();
    }, [reload, pState.setPage]);

    return (
        <CardBlock title="Upload History">
            <StyledTable>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>File</th>
                    <th></th>
                </tr>
                </thead>
                <tbody style={{overflow: "auto"}}>
                    {data.loadingOrError && <tr key="loading">
                        <td colSpan={4}>{data.LoadingOrErrorElement}</td>
                    </tr>}
                    {data.result?.data?.map(d => <tr key={d.id}>
                        <td>
                            {d.id}
                        </td>
                        <td>
                            {moment(d.createdAt).format("YYYY-MMM-DD")}
                        </td>
                        <td>
                            <a target="_blank" href={api.downloadSrcReportLink({id: d.id})}>{d.fileName}</a>
                        </td>
                        <td className="checkbox">
                            <IconButton size="small" onClick={() => remove({id: d.id})}>
                                <Delete />
                            </IconButton>
                        </td>
                    </tr>)}
                </tbody>
            </StyledTable>
            <Pagination value={data.result} state={pState} />
        </CardBlock>
    )
}