import {PropsWithChildren} from "react";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import {styled} from "@mui/material";

const Clickable = styled("div")<{disabled: boolean}>((props) => {
    return ({
        cursor: props.disabled ? "default" : "pointer",
        display: "inline-flex",
        justifyContent: "flex-start",
        height: "100%",
    });
})

export function SortableHeader(props: PropsWithChildren<{
    name?: string;
    column: string;
    onColumnChange(value: string): void;
    direction: "asc" | "desc";
    onDirectionChange(input: "asc" | "desc"): void;
}>) {

    return <th>
        <Clickable disabled={!props.name} onClick={() => {
            if(!props.name) return;

            if(props.column === props.name) {
                props.onDirectionChange(props.direction === "asc" ? "desc": "asc");
                return;
            }

            props.onColumnChange(props.name);
            props.onDirectionChange("asc");
        }}>
            {props.children}

            <div style={{
                height: "1em", fontSize: "1rem", paddingLeft: 8
            }}>
                {props.column === props.name ? props.direction === "asc" ?
                    <ArrowUpward fontSize="inherit" /> :
                    <ArrowDownward fontSize="inherit" /> : null}
            </div>
        </Clickable>
    </th>
}