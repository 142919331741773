import {MoreHoriz} from "@mui/icons-material";
import {Menu, MenuItem, MenuList, Popover, styled} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useState} from "react";
import {useNavigate} from "react-router-dom"
import {storeCopperLink} from "../../store/Copper";

const Wrapper = styled("div")(() => ({
    fontSize: "1.2rem",
    height: "1em",
    width: "1em",
    borderRadius: "50%",
    padding: 4,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
        backgroundColor: grey["200"]
    }
}))

export function MoreButton(props: {
    id: number
    copperId?: number;
}) {
    const [open, setOpen] = useState(false);
    const [element, setElement] = useState<HTMLDivElement|null>(null);
    const nav = useNavigate();

    return (
        <>
            <Wrapper ref={setElement} onClick={() => setOpen(true)}>
                <MoreHoriz fontSize="inherit" />
            </Wrapper>
            {open && <Menu anchorEl={element} open onClose={() => setOpen(false)}>
                {props.copperId && <MenuItem onClick={() => window.open(storeCopperLink(props.copperId as any), "_blank")}>
                    Open in CRM
                </MenuItem>}
                <MenuItem onClick={() => nav("/store/" + props.id)}>
                    View Store Report
                </MenuItem>
            </Menu>}
        </>
    )
}