import { Grid } from "@mui/material";
import {useParams} from "react-router-dom";
import {Container} from "../misc/Container";
import { PageContent } from "../misc/PageContent";
import {Header} from "./Header";
import {History} from "./History";
import {Copper} from "./Copper";

export function StorePage() {
    const params = useParams();

    return (
        <Container>
            <PageContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Header id={params.id} />
                    </Grid>
                    <Grid item style={{width: 400}}>
                        <History id={params.id} />
                    </Grid>
                    <Grid item xs>
                        <Copper id={params.id} />
                    </Grid>
                </Grid>
            </PageContent>
        </Container>
    )
}