import {Card, Checkbox, Grid, styled, TextField} from "@mui/material";
import { CardLabel } from "./CardLabel";
import {Downloader} from "./Downloader";
import {CompactSelect} from "../CompactSelect";
import { CreateTripWithSelection } from "./CreateTripWithSelection";
import {StyledTableInner} from "../../misc/StyledTable";
import {MoreButton} from "./MoreButton";
import {downloadBlob, formatCents, useAsync2} from "nate-react-api-helpers";
import {api, PaginateResult, SummaryRow} from "../../api/API";
import {Pagination, usePageState} from "../../misc/Pagination";
import {useContext, useState} from "react";
import {availableMonths, DateSelectedContext} from "../DatePickerProvider";
import { TrajectoryGraph } from "./TrajectoryGraph";
import { SubtleLink } from "../../misc/SubtleLink";
import {Delta} from "../Delta";
import {DeltaDollarCell} from "./DeltaDollarCell";
import {useDataSource} from "../../misc/DataSource";
import {PeriodSelector} from "../../misc/PeriodSelector";
import {IntervalSelector} from "../../misc/IntervalSelector";
import { SortableHeader } from "./SortableHeader";


export function SummaryTable<U extends {[k: string]: any}>(props: {
    title: any;
    data: (input: U & {
        detail: string, search: string,
        pageSize: number; page: number;
        orderBy: string;
        orderByDirection: "asc" | "desc"
    }) => Promise<PaginateResult<SummaryRow>|null>,
    search?: U,
    searchDeps?: any[]
    notStore?: boolean;
}) {
    const pgState = usePageState(8);
    const {detail} = useContext(DateSelectedContext);
    const [selected, setSelected] = useState<number[]>([]);
    const [search, setSearch] = useState("");
    const [orderBy, setOrderBy] = useState("name");
    const [orderByDirection, setOrderByDirection] = useState<"asc"|"desc">("desc");

    let searchObj = props.search || {};
    let searchDeps = props.searchDeps || [];

    const data = useDataSource(props.data, Object.assign({
            detail: detail,
            search: search,
            pageSize: pgState.pageSize,
            page: pgState.page,
            orderBy,
            orderByDirection,

        }, searchObj as U), [
            pgState.page, detail, search,
            orderBy, orderByDirection,
            ...searchDeps]);

    const isStore = !props.notStore;
    const trip = isStore;
    const checkbox = trip;
    const ordering = {
        column: orderBy,
        onColumnChange: setOrderBy,
        direction: orderByDirection,
        onDirectionChange: setOrderByDirection,
    }

    return (
        <Card style={{height: "100%", width: "100%"}}>
            <CardLabel>
                {props.title}
            </CardLabel>
            <div style={{padding: 4, paddingLeft: 16, paddingRight: 16}}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <TextField variant="outlined" onChange={e => setSearch(e.target.value)} placeholder="Filter for Name or Tag" size="small" fullWidth />
                    </Grid>
                    <Grid item>
                        <Downloader fileName="trending-downwards.csv" onDownload={data.download} />
                    </Grid>
                    <Grid item>
                        <IntervalSelector />
                    </Grid>
                    <Grid item>
                        <PeriodSelector />
                    </Grid>
                </Grid>
            </div>
            {trip && <CreateTripWithSelection selected={selected} />}

            {data.LoadingOrErrorElement ? <div style={{height: 200}}>
                {data.LoadingOrErrorElement}
            </div> :
                <StyledTableInner>
                    <thead>
                    <tr>
                        {checkbox && <th />}
                        <SortableHeader {...ordering} name="name">Name</SortableHeader>
                        <SortableHeader {...ordering} name="period_0">This Period</SortableHeader>
                        <SortableHeader {...ordering} name="period_1">Period-1</SortableHeader>
                        <SortableHeader {...ordering} name="period_2">Period-2</SortableHeader>
                        <SortableHeader {...ordering} name="avg">Last-6 Avg</SortableHeader>
                        <SortableHeader {...ordering} name="peak">Last-6 Peak</SortableHeader>
                        <th></th>
                        {isStore && <th></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {data.result?.data?.map(rw => <tr key={rw.id}>
                        {checkbox && <td className="checkbox">
                            <Checkbox onClick={e => {
                                if(selected.indexOf(rw.id) !== -1) {
                                    setSelected(old => old.filter(r => r !== rw.id));
                                } else {
                                    setSelected(old => [...old, rw.id]);
                                }
                            }} checked={selected.indexOf(rw.id) !== -1} />
                        </td>}
                        <td>
                            {isStore ? <SubtleLink to={"/store/" + rw.id}>{rw.name}</SubtleLink> : rw.name}
                        </td>
                        <DeltaDollarCell>
                            {formatCents(rw.period0)}
                            <Delta basis={rw.period1} current={rw.period0} noIcon />
                        </DeltaDollarCell>
                        <DeltaDollarCell>
                            {formatCents(rw.period1)}
                            <Delta basis={rw.period2} current={rw.period1} noIcon />
                        </DeltaDollarCell>
                        <DeltaDollarCell>
                            {formatCents(rw.period2)}
                            <Delta basis={rw.period3} current={rw.period2} noIcon />
                        </DeltaDollarCell>
                        <td>{formatCents(rw.avg)}</td>
                        <td>{formatCents(rw.peak)}</td>
                        <td>
                            <TrajectoryGraph samples={[rw.period5, rw.period4, rw.period3, rw.period2, rw.period1, rw.period0]} />
                        </td>
                        {isStore && <td>
                            <MoreButton id={rw.id} />
                        </td>}
                    </tr>)}
                    </tbody>
                </StyledTableInner>
            }

            <Pagination value={data.result} state={pgState} />
        </Card>
    )
}