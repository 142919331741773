import {ResponsiveLine} from "@nivo/line";
import {Moment} from "moment";
import {HomeTooltip} from "./HomeTooltip"
import { useMemo } from "react";

let colors = ["#3796BA", "rgba(55, 150, 186, .3)"];

export function LineGraph(props: {
    minHeight: number
    loading: any;
    data: {
        date: Moment;
        x: string;
        y: number | null;
    }[][];
    ticks?: (string|null)[],
}) {

    const data = useMemo(() => {
        return props.data.map((set, index) => ({
            id: index.toString(),
            color: colors[index],
            data: set,
        }));
    }, [props.data]);

    return <div style={{
        minHeight: props.minHeight,
        height: "95%",
        padding: 16,
    }}>
        {props.loading ? props.loading :
            <ResponsiveLine
                data={data}
                margin={{bottom: 20, right: 10, left: 10}}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    reverse: false }}
                pointSize={0}
                colors={c => c.color}
                pointBorderWidth={0}
                axisLeft={null}
                axisRight={null}
                axisTop={null}
                axisBottom={{
                    tickPadding: 8,
                    tickSize: 0,
                    legend: `test`,
                    legendPosition: 'middle',
                    legendOffset: 46,
                    tickValues: props.ticks,
                }}
                enableGridX={false}
                enableGridY={false}
                enableSlices="x"
                sliceTooltip={HomeTooltip}
            />}
    </div>
}

