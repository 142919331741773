import {Button, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import { useState } from "react";
import { TDialog } from "./TDialog";
import {FileDrop} from "./FileDrop";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../api/API";
import {Link} from "react-router-dom";
import {green} from "@mui/material/colors";
import {UploadFile} from "@mui/icons-material";


export function UploadData(props: {
    show: boolean;
    onDone(): void;
}) {
    const [progress, setProgress] = useState<null|number>(null);
    const [done, setDone] = useState(false);

    const upload = useAsyncAction(async (input) => {
        let lastPercent = 0;
        setProgress(0)

        const result = await api.uploadSrcReport({
            files: input,
        }, prog => {
            if(!prog.lengthComputable) return;
            let percent = Math.round(prog.loaded / prog.total)
            if(percent !== lastPercent) {
                setProgress(percent);
            }
        });
        setDone(true);

        return result;
    }, []);

    return (
        <>
            {props.show && <TDialog width="sm" title="Upload Sales Data" onClose={() => {
                if(done) window.location.reload();
                props.onDone();
            }} cancelText={done ? "Done" : "Close"}>
                {!done ? <>
                    {!upload.loading && <FileDrop multiple onDrop={input => upload.callback(input)} label="Click Here or Drop Report Files" />}

                    <div style={{height: 10}} />
                    <Grid container spacing={1}>
                        {upload.loading && <Grid item>
                            {upload.LoadingElement}
                        </Grid>}
                        {upload.loading && <Grid item>
                            <Typography>Uploading...</Typography>
                            <Typography>{progress}% complete</Typography>
                        </Grid>}
                        {upload.error && <Grid item>
                            <Typography>{upload.LoadingElement}</Typography>
                        </Grid>}
                    </Grid>
                </> : <>
                    <Typography variant="h6" style={{color: green["700"]}}>Success</Typography>
                    <div style={{height: 10}} />
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button onClick={() => setDone(false)} variant="outlined">Upload More</Button>
                        </Grid>
                        <Grid item>
                            <Link to={"/settings"} onClick={() => props.onDone()} style={{textDecoration: "none", color: "initial"}}>
                                <Button variant="outlined">View Import</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </>}
            </TDialog>}
        </>
    )
}