import {useContext} from "react";
import {
    availableMonths,
    availableQuarters,
    availableYears,
    DateSelectedContext,
    Resolution
} from "../home/DatePickerProvider";
import {CompactSelect} from "../home/CompactSelect";
import {first} from "nate-react-api-helpers";

const labels = [
    {label: "1 Month", resolution: Resolution.Month},
    {label: "1 Quarter", resolution: Resolution.Quarter},
    {label: "1 Year", resolution: Resolution.Year},
] ;

const options = labels.map(l => l.label)

export function IntervalSelector() {
    const ctx = useContext(DateSelectedContext);

    return (
        <CompactSelect label="Interval" value={first(labels, v => v.resolution === ctx.detailResolution)?.label || ""}
                       onChange={val => {
            const res = first(labels, v => v.label === val)?.resolution

            switch(res) {
                default:
                case Resolution.Month:
                    ctx.changeDetail(availableMonths[0], Resolution.Month);
                    break;
                case Resolution.Quarter:
                    ctx.changeDetail(availableQuarters[0], res);
                    break;
                case Resolution.Year:
                    ctx.changeDetail(availableYears[0], res);
                    break;
            }
        }} options={options} />
    );
}