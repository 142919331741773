import {Divider, Grid, IconButton, styled} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {PaginateResult} from "../api/API";
import {useState} from "react";
import { PageButton } from "./PageButton";

export function usePageState(pageSize: number = 50) {
    const [page, setPage] = useState(0);

    return {
        page: page,
        pageSize: pageSize,
        setPage: setPage,
    }
}

export function Pagination(props: {
    value: PaginateResult<any> | null;
    state: {
        page: number;
        pageSize: number
        setPage(n: number): void;
    }
}) {

    const page = props.state.page + 1;
    let maxPage = Math.ceil((props.value?.totalCount || 0) / props.state.pageSize);

    // null is for ellipsis
    let pages: (number|null)[] = [];

    // 1 2 3 4 5 6 7
    // 1 2 3 4 5 . 10
    // 1 . 4 5 6 . 10
    // 1 . 6 7 8 9 10

    const isRightAligned = page >= maxPage - 3 || maxPage <= 7;
    const isLeftAligned = page <= 4 || maxPage <= 7;

    if(isRightAligned && isLeftAligned) {
        for(let i = 1; i <= maxPage; i++)
            pages.push(i);
    } else if(isRightAligned) {
        pages.push(1, null);
        for(let i = 5; i >= 0; i--)
            pages.push(maxPage - i);
    } else if(isLeftAligned) {
        pages.push(1,2,3,4,5,null);
        pages.push(maxPage);
    } else {
        pages.push(1,null,page-1,page,page+1,null,maxPage);
    }

    return (
        <>
            <Divider />
            <div style={{height: 4}} />
            <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item>
                    <IconButton disabled={props.state.page === 0} onClick={() => props.state.setPage(props.state.page-1)}>
                        <ChevronLeft />
                    </IconButton>
                </Grid>
                {pages.map((i, index) =>
                    <Grid item key={index}><PageButton page={i} onClick={() => i && props.state.setPage(i-1)} selected={i === page} /></Grid>
                )}
                <Grid item>
                    <IconButton
                        disabled={page >= maxPage}
                        onClick={() => props.state.setPage(props.state.page+1)}
                    >
                        <ChevronRight />
                    </IconButton>
                </Grid>
            </Grid>


        </>
    )
}

