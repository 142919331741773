import {Grid, IconButton, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";

export function DefaultPriceRightActions(props: {
    selected: number[];
    onUpdate(input: any): void;
}) {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <Typography style={{fontSize: "0.85rem"}}>
                    Click text to edit
                </Typography>
            </Grid>
            <Grid item>
                <IconButton size="small"
                            disabled={props.selected.length === 0}
                            onClick={() => props.onUpdate({remove: props.selected})}
                >
                    <Delete />
                </IconButton>
            </Grid>
        </Grid>
    )
}