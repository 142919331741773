import {Divider, IconButton, Menu, MenuItem} from "@mui/material";
import {Person} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";
import {useAuthenticated} from "nate-react-api-helpers";
import {PopoverActions} from "@mui/material/Popover/Popover";
import {api} from "../api/API";

export function UserInfo() {
    const [anchor, setAnchor] = useState<HTMLElement|null>(null);
    const auth = useAuthenticated();
    let r = auth.lastPingResult as {email: string} | null;
    const popoverActions = useRef<PopoverActions>();

    if (!r) return null;

    return (
        <div>
            <IconButton onClick={e => setAnchor(e.currentTarget)} style={{backgroundColor: "white"}} size="small">
                <Person />
            </IconButton>
            <Menu anchorEl={anchor}
                  action={popoverActions as any}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={!!anchor} onClose={() => setAnchor(null)}>
                <div style={{padding: 16, minWidth: 250}} ref={() => {
                    setTimeout(() => {
                        if (popoverActions.current) {
                            popoverActions.current.updatePosition();
                        }
                    });
                }}>
                    Hello,<br />
                    {r.email}
                </div>
                <Divider />
                <MenuItem onClick={() => {api.logout()}}>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )
}