import {APIBase} from "nate-react-api-helpers";
import {ProgressCallback} from "nate-react-api-helpers/lib/Fetcher";

class API extends APIBase {
    constructor() {
        super({
            jwtRefreshEndpoint: "/api/auth/refresh",
            apiVersion: "1.0",
        });
    }

    ping() {
        return this.fetcher.get("/api/ping");
    }

    login(input: {username: string; password: string;}) {
        return this.fetcher.postForAuth("/api/auth/login", input);
    }

    logout() {
        // @ts-ignore
        window.location = "/api/auth/logout";
    }

    uploadSrcReport(input: {
        files: File[]
    }, onProgress: ProgressCallback) {
        let fd: {[k: string]: Blob | string} = {};
        input.files.map((f, index) => {
            fd[index.toString()] = f;
        });

        return this.fetcher.postFormDataWithProgress<{importId: number}>("/api/src-report/upload", fd, onProgress)
    }

    getSrcReportHistory(input: PaginateInput) {
        return this.fetcher.get<PaginateResult<Import>>("/api/src-report/history", input);
    }

    removeSrcReport(input: {id: number}) {
        return this.fetcher.post<{}>("/api/src-report/remove", input);
    }

    downloadSrcReportLink(input: {id: number}) {
        return "/api/src-report/download?id=" + input.id;
    }

    getDefaultPrices() {
        return this.fetcher.get<DefaultPrice[]>("/api/default-prices");
    }

    updateDefaultPrices(input: {
        remove: number[];
        update: DefaultPrice;
        create: DefaultPrice
    }) {
        return this.fetcher.post("/api/default-prices", input);
    }

    getHomePageHeader(input: {
        year: number;
    }) {
        return this.fetcher.get<{
            monthlyAverage: {
                current: number;
                basis: number;
            }
            byMonth: {
                current: {month: DateString, cents: number}[];
                basis: {month: DateString, cents: number}[];
            }
            yearlyTotal: {
                current: number;
                basis: number;
                currentIsEstimate: boolean;
            }
            topPerformers: {
                id: number
                name: string
                total: number
                monthlyAverage: number
            }[]
        }>("/api/dashboard/header", input)
    }

    trendingDown(input: PaginateInput) {
        return this.fetcher.get<PaginateResult<SummaryRow>>("/api/dashboard/trending-down", input)
    }

    trendingUp(input: PaginateInput) {
        return this.fetcher.get<PaginateResult<SummaryRow>>("/api/dashboard/trending-up", input)
    }

    byRegion(input: PaginateInput) {
        return this.fetcher.get<PaginateResult<SummaryRow>>("/api/dashboard/by-region", input)
    }

    lowVolume(input: PaginateInput) {
        return this.fetcher.get<PaginateResult<SummaryRow>>("/api/dashboard/low-volume", input)
    }

    zeroMonths(input: PaginateInput & {
        nZeros: number;
        endingOn: string;
    }) {
        return this.fetcher.get<PaginateResult<SummaryRow12>>("/api/dashboard/zero-months", input)
    }

    storeDetails(props: {id: number}) {
        return this.fetcher.get<StoreDetail>("/api/store/details", props);
    }

    storeHeader(input: {id: number}) {
        return this.fetcher.get<StoreHeader>("/api/store/header", input)
    }

    storeSummary(input: {id: number}) {
        return this.fetcher.get<StoreSummary>("/api/store/summary", input)
    }

    storeHistory(input: PaginateInput & {id: number}) {
        return this.fetcher.get<PaginateResult<StoreHistory>>("/api/store/history", input)
    }

    storeSearch(input: {search: string} | {byId: CSVString}) {
        return this.fetcher.get<Store[]>("/api/stores/search", input);
    }

    report(input: {
        search: string;
        columns: string[];
        dateStart: Date;
        dateEnd: Date;
    }) {
        return this.fetcher.post<Report>("/api/report", input);
    }

    pivotReport(input: {
        search: string;
        columns: string[];
        dateStart: Date;
        dateEnd: Date;
    }) {
        return this.fetcher.post<PivotReport>("/api/pivot-report", input);
    }
}

export type PivotReport = {
    columns: ReportPivotColumn[];
    values: PivotRow[];
}

export type PivotRow = {
    prefix: string[];
    values: PivotValue[];
}

export type PivotValue = {
    purchased: boolean;
    total: Cents;
}

export type ReportPivotColumn = {
    name: string;
    kind: "money" | "string" | "number" | "pivot";
}

export type Report = {
    columns: ReportColumn[];
    values: string[][];
}

export type ReportColumn = {
    name: string;
    kind: "money" | "string" | "number";
}

type CSVString = string;

export type Store = {
    id: number;
    name: string;
    refNumber: string;
    address1: string;
    city: string;
    province: string;
    postal: string;
}

export type StoreDetail = {
    id: number;
    copperId: number;
    name: string;
    address1: string;
    city: string;
    province: string;
    postal: string;
    region: string;

    contacts: Contact[]
}

export type Contact = {
    id: number;
    copperId: number;
    name : string;
    title: string;
    email: string;
    phone: string;
}

export type StoreSummary = {
    list: {
        date: DateString;
        current: number;
    }[];
    last12: {
        monthAvg: number;
        total: number;

        prevMonthAvg: number;
        prevTotal: number;
    },
    lifetime: {
        total: number;
        ytd: number;
        ageYears: number

        prevYtd: number;
    }
}

export type StoreHistory = {
    date: DateString;
    current: number;
    lastMonth?: number;
    lastYear?: number;
}

export type StoreHeader = {
    id: number;
    name: string;
    refNumber: string;
    copperId?: number;

    createdAt: DateString;
    createdByImport: number;
    updatedAt: DateString;
    updatedByImport: number;

    tags: string[];
}

export type SummaryRow = {
    id: number;
    name: string;
    period0: number;
    period1: number;
    period2: number;
    period3: number;
    period4: number;
    period5: number;
    avg: number;
    peak: number;
}

export type SummaryRow12 = {
    id: number;
    copperId?: number;
    name: string;
    period0: number;
    period1: number;
    period2: number;
    period3: number;
    period4: number;
    period5: number;
    period6: number;
    period7: number;
    period8: number;
    period9: number;
    period10: number;
    period11: number;
    avg: number;
    peak: number;
}

export type DefaultPrice = {
    id: number;
    reference: string;
    description: string;
    defaultUnitPrice: Cents;
}

type Cents = number;

export type PaginateResult<T> = {
    data?: T[];
    totalCount: number;
}

type DateString = string;
type Import = {
    id: number;
    createdAt: DateString;
    fileName: string;
}

type PaginateInput = Partial<{
    search: string;
    orderBy: string;
    orderByDirection: "asc" | "desc";
    download: boolean;
    page: number;
    pageSize: number;
}>

export const api = new API();