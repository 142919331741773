import {SummaryTable} from "./summary/SummaryTable";
import {CardLabelSelector} from "./summary/CardLabelSelector";
import {useState} from "react";
import {api} from "../api/API";

const options = [{
    name: "Trending Downwards",
    id: "down",
},{
    name: "Trending Upwards",
    id: "up",
}]

export function Trending() {

    const [type, setType] = useState("up");

    return (<SummaryTable
        title={<CardLabelSelector value={type} options={options} onChange={setType} />}
        data={async (input) => {
            const {type, ...other} = input;
            switch(type) {
                case "up":
                    return api.trendingUp(other);
                case "down":
                    return api.trendingDown(other);
                default:
                    return null;
            }
        }}
        search={{type: type}}
        searchDeps={[type]}
    />)
}