import {SliceTooltipProps} from "@nivo/line";
import {Card, styled} from "@mui/material";
import {formatCents, last} from "nate-react-api-helpers";
import {Delta} from "./Delta";

const Date = styled("div")(({theme}) => ({
    fontSize: 12,
    fontWeight: 600,
}))

const Money = styled("div")(({theme}) => ({
    fontSize: "1rem",
    textAlign: "right",
}))

export function HomeTooltip(props: SliceTooltipProps) {
    const thisYr = props.slice.points[0]?.data as any;
    const lastYr = props.slice.points[1]?.data as any;

    return (
        <Card style={{borderRadius: 4, padding: 4, paddingLeft: 6, paddingRight: 6}}>
            {thisYr && <div>
                <Date>{thisYr.date.format("MMMM YYYY")}</Date>
                <Money>
                    {formatCents(thisYr.y)}
                </Money>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    {thisYr && lastYr && <Delta basis={lastYr.y} current={thisYr.y} />}
                </div>
            </div>}
            {thisYr && lastYr && <div style={{height: 8}} />}
            {lastYr && <div>
                <Date>{lastYr.date.format("MMMM YYYY")}</Date>
                <Money>{formatCents(lastYr.y)}</Money>
            </div>}
        </Card>
    );
}