import {styled} from "@mui/material";
import {PropsWithChildren} from "react";


const Wrapper = styled("div")(() => ({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-end",

    "& .delta": {
        marginLeft: 4,
        minWidth: 36,
        justifyContent: "flex-start"
    }
}))

export function DeltaDollarCell(props: PropsWithChildren<{}>) {

    return (
        <td>
            <Wrapper>
                {props.children}
            </Wrapper>
        </td>
    )
}