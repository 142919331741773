import { AddCircle } from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {Grid, styled} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Buttonish = styled("div")(() => ({
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderLeft: "1px solid " + grey["300"],
    borderRight: "1px solid " + grey["300"],
    backgroundColor: grey["300"],
    height: "100%",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: grey["400"],
    }
}))

export function CreateTripWithSelection(props: {
    selected: number[]
}) {
    const nav = useNavigate();
    return (<div style={{
        paddingLeft: 16,
        backgroundColor: grey["200"],
        borderTop: "1px solid " + grey["300"],
        borderBottom: "1px solid " + grey["300"],
        display: "flex"
    }}>
        <Buttonish onClick={() => {
            nav("/plan-trip?ids=" + props.selected.join(","))
        }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <div style={{fontSize: "1.2rem", height: "1em"}}>
                        <AddCircle fontSize="inherit" />
                    </div>
                </Grid>
                <Grid item style={{fontSize: "0.8rem"}}>
                    Create Trip with Selection ({props.selected.length})
                </Grid>
            </Grid>
        </Buttonish>
    </div>)
}