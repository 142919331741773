import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import {styled, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

const Wrapper = styled("div")<{active: boolean}>(({theme, active}) => ({
    border: "3px dashed " + (active ? theme.palette.primary.main : grey["300"]),
    borderRadius: 5,
    padding: theme.spacing(2),
    textAlign: "center",
}))

export function FileDrop(props: {
    label: string;
    noClick?: boolean;
    accept?: string | string[];
    multiple?: boolean;
    onDrop(
        acceptedFiles: File[],
        fileRejections: FileRejection[],
        event: DropEvent
    ): void;
}) {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        noClick: props.noClick,
        onDrop: props.onDrop,
        accept: props.accept,
        multiple: props.multiple,
    });

    return (
        <div {...getRootProps()}>
            <Wrapper active={isDragActive}>
                <Typography color="textSecondary">{props.label}</Typography>
            </Wrapper>
            <input {...getInputProps()} />
        </div>
    );
}