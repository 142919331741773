import {ArrowDownward, ArrowUpward, FiberManualRecord } from "@mui/icons-material"
import {green, grey, red} from "@mui/material/colors";


export function Delta(props: {
    basis: number;
    current: number;
    noIcon?: boolean;
}) {
    let delta = ((props.current - props.basis) / Math.max(props.basis, 1) * 100);
    let percent = delta.toFixed(0) + "%"

    if(delta >= 1000) percent = "∞"
    if(delta <= -1000) percent = "∞";

    let icon: any;
    let color: string;

    if(props.current == props.basis) {
        color = grey["600"]
        icon = <FiberManualRecord fontSize="inherit" color="inherit" />
    } else if(props.current < props.basis) {
        color = red["600"];
        icon = <ArrowDownward fontSize="inherit" color="inherit" />;
    } else {
        color = green["600"];
        icon = <ArrowUpward fontSize="inherit" color="inherit" />;
    }

    return (
        <div className="delta" style={{display: "inline-flex", flexDirection: "row", alignItems: "center", color: color, fontSize: "0.8rem"}}>
            {props.noIcon ? null : icon}
            <div style={{paddingLeft: props.noIcon ? 0 : 4}}>{percent}</div>
        </div>
    )
}