import {orange} from "@mui/material/colors";


const height = 16;
const width = 40;

export function TrajectoryGraph(props: {
    samples: number[];
}) {
    if(props.samples.length === 0 || props.samples.length === 1) return null;

    const min = Math.min(...props.samples);
    const max = Math.max(...props.samples);
    let dy = max - min;
    if(dy === 0) dy = 2;
    const dx = width / (props.samples.length - 1);
    const paddingX = 2;
    const paddingY = 2;

    const svgPath = props.samples.map((p, index) => {
        const y = height - (((p - min) / dy) * height) + paddingY;
        const x = index * dx + paddingX;

        if(index === 0) return `M ${x} ${y}`;
        return `L ${x} ${y}`
    }).join(" ");

    return (
        <div>
            <svg width={width + paddingX * 2} height={height + paddingY * 2}>
                <path d={svgPath} fill="transparent" strokeLinejoin="round" strokeWidth={2} stroke={orange["600"]} />
            </svg>
        </div>
    )
}