import {Card, Grid, styled} from "@mui/material";
import {LineGraph} from "./LineGraph";
import {CompactSelect} from "./CompactSelect";
import {grey} from "@mui/material/colors";
import {Label} from "../misc/Label";
import {BigNumber} from "../misc/BigNumber";
import {AsyncResult2, formatCents, useAsync2} from "nate-react-api-helpers";
import {Delta} from "./Delta";
import {Link} from "react-router-dom";
import {useContext, useMemo} from "react";
import {api} from "../api/API";
import {availableYears, DateSelectedContext} from "./DatePickerProvider";
import moment from "moment";
import {Cell, HeaderCell} from "./HeaderCell";

export function Header() {
    const {year, changeYear} = useContext(DateSelectedContext);
    const data = useAsync2(input => api.getHomePageHeader(input), {year: year as any}, [year]);
    const graphData = useMemo(() => {
        if(!data.result) return [];

        let formatData = (input: {month: string; cents: number}[]) => {
            let list = input.map(i => {
                const date = moment(i.month).utc();

                return {
                    date: date,
                    x: date.format("MMM"),
                    y: i.cents as number | null,
                };
            });

            // fill in missing months with y=null

            if(list.length === 0) {
                let date = moment().utc().startOf("month");
                list.push({
                    date: date,
                    x: date.format("MMM"),
                    y: null,
                })
            }

            while(list[0].x !== "Jan") {
                let first = list[0];
                let date = first.date.clone().add(-1, "month");
                list.unshift({
                    date: date,
                    x: date.format("MMM"),
                    y: null,
                })
            }

            while(list[list.length-1].x !== "Dec") {
                let last = list[list.length-1];
                let date = last.date.clone().add(1, "month");
                list.push({
                    date: date,
                    x: date.format("MMM"),
                    y: null,
                })
            }

            return list;
        }

        return [
            formatData(data.result.byMonth.current),
            formatData(data.result.byMonth.basis)
        ];
    }, [data.result]);

    return (
        <Card>
            <Grid container alignItems="stretch">
                <Grid item xs={7} style={{position: "relative"}}>
                    <LineGraph minHeight={200} data={graphData} loading={data.LoadingOrErrorElement} />
                    <div style={{position: "absolute", top: 16, right: 16, zIndex: 5}}>
                        <CompactSelect
                            label="Period"
                            value={year}
                            onChange={changeYear}
                            options={availableYears} />
                    </div>
                </Grid>
                <Grid item xs={5} style={{borderLeft: "1px solid " + grey["200"]}}>
                    <Grid container alignItems="stretch">
                        <Grid item xs={6}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", height: "100%"}}>
                                <HeaderCell
                                    label="Monthly Average"
                                    value={formatCents(data.result?.monthlyAverage.current)}
                                    current={data.result?.monthlyAverage.current}
                                    basis={data.result?.monthlyAverage.basis}
                                    data={data}
                                />
                                <HeaderCell
                                    label={data.result?.yearlyTotal.currentIsEstimate ? "Est Year Total" : "Year Total"}
                                    value={formatCents(data.result?.yearlyTotal.current)}
                                    current={data.result?.yearlyTotal.current}
                                    basis={data.result?.yearlyTotal.basis}
                                    data={data}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} style={{borderLeft: "1px solid " + grey["200"]}}>
                            <Cell>
                                {data.result ? <>
                                    <Label>Top Performers</Label>
                                    {data.result.topPerformers.map(t =>
                                        <Link key={t.id} to={"/store/" + t.id} style={{color: "inherit", textDecoration: "none"}}>
                                            <div style={{marginLeft: -16, marginRight: -16}}>
                                                <Clickable style={{padding: 8, paddingLeft: 16, paddingRight: 16, width: "100%"}}>
                                                    <div>{t.name}</div>
                                                    <Grid container
                                                          justifyContent="space-between"
                                                          style={{fontSize: "0.8rem", color: grey["600"]}}>
                                                        <Grid item>
                                                            Total: {formatCents(t.total)}
                                                        </Grid>
                                                        <Grid item>
                                                            Avg: {formatCents(t.monthlyAverage)}
                                                        </Grid>
                                                    </Grid>
                                                </Clickable>
                                            </div>
                                        </Link>
                                    )}
                                </> : <>
                                    {loadingIndicator(data)}
                                </>}
                            </Cell>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

const Clickable = styled("div")(() => ({
    cursor: "pointer",
    "&:hover": {
        backgroundColor: grey["200"],
    }
}))

function loadingIndicator(input: AsyncResult2<any>) {
    if(input.loading) {
        return input.LoadingOrErrorElement;
    }

    return null;
}