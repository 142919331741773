import {Card, Menu, MenuItem, Popper, styled} from "@mui/material";
import {useEffect, useState} from "react";
import {grey} from "@mui/material/colors";
import {useAsync2} from "nate-react-api-helpers";
import {api, Store} from "../api/API";
import {useNavigate} from "react-router-dom";

const Text = styled("input")(() => ({
    backgroundColor: "hsla(0,0%,0%,0.1)",
    borderRadius: 4,

    border: "1px solid hsla(0,0%,0%,0.2)",
    padding: 10,
    fontSize: "1rem",
    transition: "width 0.7s ease-in-out",


    "&::placeholder": {
        color: grey["800"],
    }
}))

const defaultWidth = 170;
const expandedWidth = 300;

export function Search() {
    const [width, setWidth] = useState(defaultWidth);
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement |null>(null);
    const searcher = useAsync2(input => {
        if(input.search == "") return Promise.resolve<Store[]>([]);
        return api.storeSearch(input);
    }, {search: search}, [search]);

    const nav = useNavigate();
    const [selected, setSelected] = useState<number|null>(null);
    let options = searcher.asList;

    useEffect(() => {
        setSelected(null);
    }, [options]);

    return (
        <div>
            <Text type="text" placeholder="Find Customer"
                  ref={setAnchorEl}
                   onChange={e => setSearch(e.target.value)}
                    onFocus={() => setWidth(expandedWidth)}
                   onBlur={() => {
                       if(search === "") setWidth(defaultWidth);
                   }}
                  onKeyDown={e => {
                      function setNumber(v: number) {
                          if(options.length === 0) {
                              setSelected(null);
                              return;
                          }

                            if(v >= options.length) {
                                v = 0;
                            }

                            if(v < 0) {
                                v = options.length - 1;
                            }

                            setSelected(v);
                      }

                      switch(e.key) {
                          case "ArrowDown":
                              if(selected === null) {
                                  setNumber(0);
                              } else {
                                    setNumber(selected+1);
                              }
                                break;
                          case "ArrowUp":
                              if(selected === null) {
                                  setNumber(-1)
                              } else {
                                  setNumber(selected-1);
                              }
                              break;
                          case "Enter":
                              if(selected !== null) {
                                  let el = options[selected];
                                  if(el) {
                                      setSearch("");
                                      nav("/store/" + el.id);
                                  }
                              }
                              break;
                      }
                  }}
                   style={{
                       width: width,
                    }} />

            <Popper anchorEl={anchorEl} open={search.length > 0} style={{zIndex: 8888}}>
                <Card style={{width: expandedWidth}}>
                    {searcher.loadingOrError ? <MenuItem>{searcher.LoadingOrErrorElement}</MenuItem> : null}
                    {!searcher.loading && searcher.asList.length === 0 && <MenuItem style={{color: grey["700"]}}>
                        No results for '{search}'
                    </MenuItem>}
                    {searcher.asList.map((v, index) => <MenuItem selected={index === selected} onClick={() => {
                        setSearch("");
                        nav("/store/" + v.id)
                    }}>
                        <div style={{whiteSpace: "normal"}}>
                            <div style={{fontSize: "1.1rem"}}>{v.name}</div>
                            {!!(v.address1 || v.city || v.province) ? (<div>{v.address1} {v.city}, {v.province}</div>) : null}
                        </div>
                    </MenuItem>)}
                </Card>
            </Popper>
        </div>
    )
}