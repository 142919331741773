import {DatePickerProvider} from "../../home/DatePickerProvider";
import {PageContent} from "../../misc/PageContent";
import {
    Button,
    Card,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    styled,
    TextField
} from "@mui/material";
import {Container} from "../../misc/Container";
import {useEffect, useState} from "react";
import moment from "moment";
import {downloadBlob, formatCents, range, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {green, grey, red} from "@mui/material/colors";
import {MonthPicker} from "./MonthPicker";

const yearStart = moment().startOf("year");
const groupingOptions = [
    "store",
    "chain",
    "distributor",
    "region",
    "province",
    "city",
    "tag",
    "month",
    "quarter",
    "product",
]

const dateFormat = "YYYY MMM"

export function PivotReportPage() {

    const [search, setSearch] = useState("");

    const [dateStart, setDateStart] = useState(yearStart.format(dateFormat));
    const [dateEnd, setDateEnd] = useState(moment().format(dateFormat));

    const [columns, setColumns] = useState<string[]>([]);

    useEffect(() => {
        if(columns.length === 0) {
            setColumns(["-"]);
            return;
        }

        if(columns[columns.length-1] !== "-") {
            if(columns.length < groupingOptions.length) {
                setColumns([...columns, "-"]);
                return;
            }
        }

        if(columns.indexOf("-") !== columns.length-1) {
            setColumns(columns.filter(g => g !== "-"));
        }

    }, [columns]);


    const report = useAsyncAction(async (input) => {
        const result = await api.pivotReport(input);
        if(input.download) {
            const csv = toCSV([result.columns.map(c => c.name), ...result.values.map(v => {
                return [...v.prefix, ...v.values.map(v => formatCents(v.total))]
            })])
            const blob = new Blob([csv], { type: "text/csv" });
            downloadBlob(blob, "report-" + moment().format("YYYY-MMM") + ".csv")
        }

        return result;
    }, []);

    const result = report.result;

    return (
        <Container>
            <DatePickerProvider>
                <PageContent>
                    <Card>
                        <div style={{padding: 16}}>
                            <div>
                                <div style={{fontSize: "1.2rem"}}>Pivot Report</div>
                            </div>
                            <Grid container spacing={2} alignItems="stretch">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs>
                                            <div>Store Search Criteria</div>
                                            <TextField variant="outlined" size="small" value={search} onChange={e => setSearch(e.target.value)} fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <div>Start</div>
                                            <MonthPicker value={dateStart} onChange={setDateStart} />
                                        </Grid>
                                        <Grid item>
                                            <div>End</div>
                                            <MonthPicker value={dateEnd} onChange={setDateEnd} />
                                        </Grid>
                                    </Grid>

                                    <div style={{height: 8}} />
                                    <div>Columns</div>
                                    <Grid container spacing={1}>
                                        {columns.map((g, index) => (
                                            <Grid item key={index}>
                                                <Select value={g} onChange={e => {
                                                    columns[index] = (e.target.value as any).toString();
                                                    setColumns(columns.slice(0))
                                                }} size="small">
                                                    <MenuItem key="none" value="-">{index !== columns.length-1 ? "(remove)" : "(none)"}</MenuItem>
                                                    {groupingOptions.map(g => <MenuItem key={g} value={g}>{ucFirst(g)}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                        ))}
                                        <Grid item xs />
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={() => {
                                                report.callback({
                                                    search: search,
                                                    columns: columns.filter(g => g !== "-"),
                                                    dateStart: moment.utc(dateStart, "YYYY MMM").toDate(),
                                                    dateEnd: moment.utc(dateEnd, "YYYY MMM").toDate(),
                                                    download: false,
                                                });
                                            }}>View Report</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" color="primary" onClick={() => {
                                                report.callback({
                                                    search: search,
                                                    columns: columns.filter(g => g !== "-"),
                                                    dateStart: moment.utc(dateStart, "YYYY MMM").toDate(),
                                                    dateEnd: moment.utc(dateEnd, "YYYY MMM").toDate(),
                                                    download: true,
                                                });
                                            }}>Download to Excel</Button>
                                        </Grid>
                                    </Grid>

                                    <div style={{height: 8}} />

                                    {report.loading ? <CircularProgress />: null}
                                    {report.error ? <div style={{color: "red"}}>{report.error}</div>: null}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider />
                        <div style={{overflowX: "auto"}}>
                            <Table>
                                <thead>
                                <tr>
                                    {result?.columns.map((c, index) =>
                                        <th key={index} style={{textAlign: "left", maxWidth: 400, whiteSpace: "nowrap"}}>
                                            {ucFirst(c.name)}
                                        </th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {result?.values.map((v, rowIndex) => <tr key={rowIndex}>
                                    {v.prefix.map((cell, cellIndex) => <td key={cellIndex} style={
                                        ["money", "number"].indexOf(result.columns[cellIndex].kind) !== -1 ? {textAlign: "right"} : undefined}>
                                        {cell}
                                    </td>)}
                                    {v.values.map((cell, cellIndex) =>
                                        <td key={cellIndex + "-pivot"} style={{
                                            backgroundColor: cell.purchased ? green["100"] : red["100"],
                                            color: cell.purchased ? green["800"] : red["800"],
                                            textAlign: "right",
                                            minWidth: 40,
                                            whiteSpace: "nowrap",
                                        }}>
                                            ${formatCents(cell.total)}
                                        </td>)}
                                </tr>)}
                                {result?.values.length === 0 ? <tr key="nothing"><td colSpan={result.columns.length}>
                                    No results. Try adjusting your search or date range.
                                </td></tr> : null}
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </PageContent>
            </DatePickerProvider>
        </Container>
    )
}

export function ucFirst(input: string) {
    if(!input) return input;
    return input[0].toUpperCase() + input.substring(1)
}

const Table = styled("table")(props => ({
    width: "100%",
    borderCollapse: "collapse",
    "& th, & td": {
        paddingLeft: 8,
        paddingRight: 8,
        borderBottom: "1px solid " + grey["200"],
        borderLeft: "1px solid " + grey["200"],

        "&:first-child": {
            borderLeft: "none",
        }
    }
}))

function toCSV(data: string[][]) {
    const rows = data.map((row) => {
        return row
            .map((value) => {
                if (/[,\r\n]/.exec(value) !== null) {
                    return '"' + value.replace(/"/g, '""') + '"';
                }

                return value;
            })
            .join(",");
    });

    return rows.join("\r\n");
}