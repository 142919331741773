import {Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {PropsWithChildren} from "react";

export function TDialog(props: PropsWithChildren<{
    title: string;
    onClose(tf: boolean): void;

    cancelText?: string;

    submitText?: string;
    onSubmit?(): void;

    width?: Breakpoint;
}>) {
    return (
        <Dialog open={true} maxWidth={props.width} fullWidth={!!props.width}>
            <DialogTitle>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        {props.title}
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => props.onClose(false)}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={()=> props.onClose(false)}>{props.cancelText || "Cancel"}</Button>
                {props.onSubmit && <Button onClick={props.onSubmit} variant="text">{props.submitText || "Submit"}</Button>}
            </DialogActions>
        </Dialog>
    )
}