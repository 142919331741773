import {downloadBlob, useAsync2} from "nate-react-api-helpers";

export function useDataSource<T extends {[k: string]: any}, U>(fx: (input: T) => Promise<U>, search: T, deps: any[]) {
    const result = useAsync2(fx, search, deps);

    return Object.assign(result, {
        download: async (name: string) => {
            const response = await fx(Object.assign({}, search, {
                download: name,
            })) as any as Response;
            const blob = await response.blob()

            downloadBlob(blob, name);
        }});
}