import {Grid, Menu, MenuItem, MenuList, Popover, styled} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import {green, grey} from "@mui/material/colors";
import {useState} from "react";

const Wrapper = styled("div")(({theme}) => ({
    backgroundColor: grey["200"],
    border: "1px solid " + grey["300"],
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: 2,
    paddingLeft: 8,
    fontSize: "0.8rem",

    "&:hover": {
    },
}));

export function CompactSelect(props: {
    label: string;
    value: string;
    onChange?(value: string): void;
    options?: string[];
}) {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement|null>(null);

    return (
        <div>
            <Wrapper ref={setAnchorEl} onClick={() => {
                if(!props.options) return;
                setOpen(true)
            }}>
                <div>
                    {props.label}
                </div>
                <div style={{width: 8}} />
                <div>
                    {props.value}
                </div>
                <div style={{height: "1em", fontSize: "0.9rem"}}>
                    <ArrowDropDown fontSize="inherit" />
                </div>
            </Wrapper>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        maxHeight: 300,
                        overflowY: "auto",
                }}}
                MenuListProps={{
                    dense: true,
                    style: {
                        display: "flex",
                        flexDirection: "column",
                    }
                }}
            >
                {props.options?.map(o => <MenuItem
                    style={{justifyContent: "flex-end"}}
                    selected={o === props.value}
                    onClick={() => {
                        setOpen(false);

                        if(props.onChange && o !== props.value) {
                            props.onChange(o)
                        }
                    }} key={o}>{o}</MenuItem>)}
            </Menu>
        </div>
    )
}