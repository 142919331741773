import {formatCents, parseCents} from "nate-react-api-helpers";
import {Checkbox, IconButton} from "@mui/material";
import {DefaultPrice} from "../api/API";
import {useEffect, useState} from "react";
import {TableText} from "../misc/TableText";
import {Check} from "@mui/icons-material";
import {green} from "@mui/material/colors";

export function DefaultPriceEditRow(props: {
    value?: DefaultPrice;
    checked?: boolean;
    onCheck?(): void;
    onUpdate(input: {
        create?: DefaultPrice
        update?: DefaultPrice
    }): void;
}) {

    const [changed, setChanged] = useState(false);
    const [reference, setReference] = useState("");
    const [description, setDescription] = useState("");
    const [unitPrice, setUnitPrice] = useState("");

    useEffect(() => {
        if(!props.value) return;

        const v = props.value;
        setReference(v.reference);
        setDescription(v.description);
        setUnitPrice(formatCents(v.defaultUnitPrice));
        setChanged(false);

    }, [props.value])

    return (
        <tr>
            <td className="checkbox">
                <Checkbox disabled={!props.onCheck} checked={props.checked} onClick={props.onCheck} />
            </td>
            <td className="table-field">
                <TableText type="text" placeholder="Start typing to create" value={reference} onChange={e => {
                    setReference(e.target.value)
                    setChanged(true)
                }} />
            </td>
            <td className="table-field">
                <TableText type="text" value={description} onChange={e => {
                    setDescription(e.target.value)
                    setChanged(true)
                }} />
            </td>
            <td className="table-field">
                <TableText type="text" value={unitPrice} style={{textAlign: "right", width: 80}} onChange={e => {
                    setUnitPrice(e.target.value)
                    setChanged(true)
                }} onBlur={() => {
                    setUnitPrice(formatCents(parseCents(unitPrice)));
                }} />
            </td>
            <td>
                {changed && <IconButton color="primary" size="small" style={{
                    border: "1px solid " + green["700"],
                    color: green["700"],
                    fontSize: 12,
                }} onClick={() => {
                    const key = props.value ? "update" : "create";

                    props.onUpdate({
                        [key]: {
                            id: props.value?.id || 0,
                            reference: reference,
                            description: description,
                            defaultUnitPrice: parseCents(unitPrice),
                        },
                    });
                }}>
                    <Check color="inherit" fontSize="inherit" />
                </IconButton>}
            </td>
        </tr>

    )
}