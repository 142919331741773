import { Link } from "react-router-dom";
import {styled} from "@mui/material";

export const SubtleLink = styled(Link)(() => ({
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",

    "&:hover": {
        textDecoration: "underline",
    }
}))

export const NoDecorationLink = styled(Link)(() => ({
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
}))