import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";
import {PropsWithChildren} from "react";

export function StyledTable(props: PropsWithChildren<{}>) {

    return (
        <div style={{marginLeft: -8, marginRight: -8}}>
            <StyledTableInner>
                {props.children}
            </StyledTableInner>
        </div>
    )
}

export function StyledTableInner(props: PropsWithChildren<{}>) {
    return (
        <div style={{width: "100%", overflow: "auto"}}>
            <TableInner>
                {props.children}
            </TableInner>
        </div>
    )
}

const TableInner = styled("table")(theme => ({
    width: "100%",
    fontSize: "0.9rem",
    borderCollapse: "collapse",
    borderSpacing: 0,

    "& td, & th": {
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        borderBottom: "1px solid " + grey["200"],

        "&:first-of-type": {
            paddingLeft: 16,
        },
        "&:last-child": {
            paddingRight: 16,
        }
    },
    "& th": {
        fontWeight: 500,
        textAlign: "left",
    },

    "& th.checkbox, & td.checkbox": {
        width: 20,
        paddingTop: 0,
        paddingBottom: 0,

        "& .MuiButtonBase-root": {
            padding: 0,
        },

        "& .MuiSvgIcon-root": {
            fontSize: 20,
        }
    },

    "& td.table-field": {
        padding: 0,
    }
}))