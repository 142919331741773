import {Card, Grid, styled, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import { NoStyleLink } from "../misc/NoStyleLink";
import {Tag} from "./Tag";
import {LineGraph} from "../home/LineGraph";
import {useMemo} from "react";
import {formatCents, useAsync2} from "nate-react-api-helpers";
import moment from "moment";
import {api} from "../api/API";
import {HeaderCell} from "../home/HeaderCell";
import {storeCopperLink} from "./Copper";

const ColumnHeader = styled("div")(() => ({
    textTransform: "uppercase",
    fontSize: "0.9rem",
    width: "100%",
    textAlign: "center",
    padding: 8,
    fontWeight: 500
}))

export function Header(props: {
    id: any;
}) {
    const headerData = useAsync2((input) => api.storeHeader(input), {id: props.id}, [props.id]);
    const graphData = useAsync2(input => api.storeSummary(input), {id: props.id}, [props.id]);
    const gData = graphData.result;

    const graph = useMemo(() => {
        if(!gData) return [[]];
        return [gData.list.map((l) => {
            let date = moment(l.date).utc();

            return {
                date: date,
                x: date.format("MMMM YYYY"),
                y: l.current,
            }
        })];
    }, [gData]);

    const graphTicks = useMemo(() => {
        if(graph[0].length === 0) return [];

        const list = graph[0];
        const maxI = list.length -1;
        let left = Math.round(maxI/8);
        let center = Math.round(maxI / 2);
        let right = Math.round(maxI * 7/8);

        return list.map((l, index) => {
            if(index === left || index === center || index === right)
                return l.x;
            return null;
        });

    }, [graph]);

    let head = headerData.result;
    let stats = graphData.result;

    return (
        <Card>
            <Grid container alignItems="stretch">
                <Grid item xs>
                    <div style={{padding: 16, display: "flex", flexDirection: "column", height: "100%"}}>
                        <Typography variant="h1" style={{fontSize: "2rem", fontWeight: "bold"}}>
                            {head?.name}{head?.refNumber && head?.refNumber !== head?.name ? " (" + head.refNumber + ")" : null}
                        </Typography>
                        {headerData.LoadingOrErrorElement}
                        {head && <div style={{color: grey["700"], fontSize: "0.8rem"}}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <div>
                                        Created on {moment(head.createdAt).format("MMM D, YYYY")} by <NoStyleLink to="/settings">import #{head.createdByImport}</NoStyleLink>
                                    </div>
                                    <div>
                                        Last updated on {moment(head.updatedAt).format("MMM D, YYYY")} by <NoStyleLink to="/settings">import #{head.updatedByImport}</NoStyleLink>
                                    </div>
                                </Grid>
                                {head.copperId && <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        {head.tags.map(t => <Tag>{t}</Tag>)}
                                        <Grid item>
                                            <a style={{color: "inherit"}} target="_blank" rel="noopener" href={storeCopperLink(head.copperId)}>View in CRM</a>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </div>}
                        <div>
                            <LineGraph minHeight={200} data={graph} loading={graphData.LoadingOrErrorElement} ticks={graphTicks} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div style={{borderLeft: "1px solid " + grey["200"], display: "flex", flexDirection: "column", height: "100%"}}>
                        <ColumnHeader>
                            Last 12 Months
                        </ColumnHeader>

                        <HeaderCell
                            label="Monthly Average"
                            value={formatCents(stats?.last12.monthAvg)}
                            current={stats?.last12.monthAvg}
                            basis={stats?.last12.prevMonthAvg}
                            data={graphData}
                        />
                        <HeaderCell
                            label="Total"
                            value={formatCents(stats?.last12.total)}
                            current={stats?.last12.total}
                            basis={stats?.last12.prevTotal}
                            data={graphData}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div style={{borderLeft: "1px solid " + grey["200"], display: "flex", flexDirection: "column", height: "100%"}}>
                        <ColumnHeader>
                            Lifetime
                        </ColumnHeader>

                        <HeaderCell
                            label="Lifetime Value"
                            value={formatCents(stats?.lifetime.total)}
                            data={graphData}
                        />

                        <HeaderCell
                            label="YTD"
                            value={formatCents(stats?.lifetime.ytd)}
                            current={stats?.lifetime.ytd}
                            basis={stats?.lifetime.prevYtd}
                            data={graphData}
                        />

                        <HeaderCell
                            label="Age"
                            value={stats ? stats.lifetime.ageYears + "yrs" : ""}
                            data={graphData}
                        />
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
}