import {Card, Divider, Grid, styled} from "@mui/material";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../api/API";
import {CardLabel} from "../home/summary/CardLabel";
import {grey} from "@mui/material/colors";
import {Email, Map, OpenInBrowser, OpenInFull, OpenInNew, Phone, PinDrop} from "@mui/icons-material";

export function storeCopperLink(id: number) {
    return "https://app.copper.com/companies/329564/app#/organization/" + id
}

export function contactCopperLink(id: number) {
    return "https://app.copper.com/companies/329564/app#/contact/" + id
}

const Title = styled("div")(() => ({
    fontSize: "0.9rem",
    color: grey["800"],
    fontWeight: 600,
}));

const Link = styled("a")(() => ({
    color: grey["800"],
    fontSize: "0.9rem",
    textDecoration: "none",

    "&:hover": {
        textDecoration: "underline",
    }
}));

export function Copper(props: {
    id: any;
}) {
    const details = useAsync2(input => api.storeDetails(input), {id: props.id}, [props.id]);
    const rs = details.result;
    return (
        <Card style={{display: "inline-block"}}>
            <CardLabel>
                Details
            </CardLabel>

            <div style={{padding: 16, paddingTop: 4}}>
            {!rs ? details.LoadingOrErrorElement : <div>
                <Grid container>
                    <Grid item>
                        <div style={{minWidth: "230px", paddingRight: 32}}>
                            <Title>Address</Title>
                            <div style={{fontSize: "0.9rem"}}>
                                <div>{rs.address1}</div>
                                <div>{rs.city}, {rs.province}</div>
                                <div>{rs.postal}</div>
                                <LinkRow icon={<PinDrop fontSize="inherit" />} href={"https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(rs.address1 + ", " + rs.city + ", " + rs.province + " " + rs.postal)} text="Open on Map" />
                                <LinkRow icon={<OpenInNew fontSize="inherit" />} href={storeCopperLink(rs.copperId)} text="View in Copper" />
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <Title>Contacts</Title>
                        <div style={{fontSize: "0.9rem"}}>
                            <Grid container spacing={2} direction="column">
                                {!rs.contacts?.length && <Grid item>
                                    None
                                </Grid>}
                                {rs.contacts?.map(c => <Grid item>
                                    <div>{c.name} {c.title}</div>
                                    <div style={{paddingLeft: 10}}>
                                        {extractCSV(c.email).map(c => <LinkRow icon={<Email fontSize="inherit" />} href={"mailto:" + c} text={c} />)}
                                        {extractCSV(c.phone).map(c => <LinkRow icon={<Phone fontSize="inherit" />} href={"tel:" + c} text={c} />)}
                                        <LinkRow icon={<OpenInNew fontSize="inherit" />} href={contactCopperLink(c.copperId)} text="View in Copper" />
                                    </div>
                                </Grid>)}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>}
            </div>
        </Card>
    )
}

function LinkRow(props: {
    icon: JSX.Element;
    href: string;
    text: string;
}) {
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <div style={{paddingRight: 8, height: "1rem", color: grey["600"], fontSize: "1rem"}}>
                {props.icon}
            </div>
            <Link target="_blank" rel="noopener noreferrer" href={props.href}>{props.text}</Link>
        </div>
    )
}

function extractCSV(value?: string) {
    return (value || "").split(",").map(v => v.trim()).filter(v => !!v);
}