import { first } from "nate-react-api-helpers"
import {Menu, MenuItem, styled} from "@mui/material";
import {grey} from "@mui/material/colors";
import {ArrowDropDown} from "@mui/icons-material";
import {useState} from "react";

const Clickable = styled("div")(() => ({
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    paddingBottom: 3,
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 4,
    cursor: "pointer",

    "&:hover": {
        backgroundColor: grey["200"],
    }
}));

type Option = {
    id: string;
    name: string;
}

export function CardLabelSelector(props: {
    options: Option[];
    value: string;
    onChange(value: string): void;
}) {
    const val = first(props.options, o => o.id === props.value);
    const [target, setTarget] = useState<HTMLDivElement|null>(null);

    return (
        <div>
            <Clickable onClick={e => setTarget(e.currentTarget as HTMLDivElement)}>
                {val?.name}

                <div style={{paddingLeft: 10, height: "1em"}}>
                    <ArrowDropDown />
                </div>
            </Clickable>
            {target && <Menu open={true} onClose={() => setTarget(null)} anchorEl={target}>
                {props.options.map(o => <MenuItem selected={o.id === props.value} key={o.id} onClick={() => {
                    setTarget(null);
                    props.onChange(o.id);
                }}>{o.name}</MenuItem>)}
            </Menu>}
        </div>
    )
}