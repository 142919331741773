import {Container} from "../misc/Container";
import {Grid, Typography} from "@mui/material";
import { UploadHistory } from "./UploadHistory";
import { PageContent } from "../misc/PageContent";
import { DefaultPrices } from "./DefaultPrices";

export function SettingsPage(props: {}) {

    return (
        <Container>
            <PageContent>
                <div style={{padding: 8, paddingTop: 0}}>
                    <Typography variant="h6">Settings</Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <UploadHistory />
                    </Grid>
                    <Grid item xs={6}>
                        <DefaultPrices />
                    </Grid>
                </Grid>
            </PageContent>
        </Container>
    )
}