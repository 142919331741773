import {useContext} from "react";
import {
    availableMonths,
    availableQuarters,
    availableYears,
    DateSelectedContext,
    Resolution
} from "../home/DatePickerProvider";
import {CompactSelect} from "../home/CompactSelect";

export function PeriodSelector() {
    const ctx = useContext(DateSelectedContext);

    let options = (function(){
        switch(ctx.detailResolution) {
            default:
            case Resolution.Month:
                return availableMonths;
            case Resolution.Quarter:
                return availableQuarters;
            case Resolution.Year:
                return availableYears;
        }
    })();

    return (
        <CompactSelect label="Period" value={ctx.detail} onChange={ctx.changeDetail} options={options} />
    );
}